<template>
    <v-container fluid id="index-container" :style="computeWidth">
        <v-layout align-center justify-center>
            <template>
                <v-row dense justify="space-around" style="height:55%">
                    <!-- <v-col
                        v-for="item in items"
                        :style="item.flex"
                    > -->
                    <template v-for="(item, index) in items">
                        <v-card
                            :key="index"
                            style="width: 23%"
                            class="mt-0"
                            elevation=15
                            max-width="350"
                            height="350"
                            @click="openPage(item)"
                        >
                            <div style="height:16rem;" class="mt-8" :class="item.image"></div>
                            <v-card-text class="my-5 text-center text-h3">{{ item.title }}</v-card-text>
                        </v-card>
                    </template>
                    <!-- </v-col> -->
                </v-row>
            </template>
        </v-layout>
    </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
    data: (vm) => ({
            items: [
                {
                    title: vm.$i18n.t("urbanRenewal.manage"),
                    flex: 3,
                    href: "UrbanRenewalTables",
                    image: 'largeicon_1'
                },
                {
                    title: vm.$i18n.t("meeting.manage"),
                    flex: 3,
                    href: "MeetingTables",
                    image: 'largeicon_2'
                },
                {
                    title: vm.$i18n.t("vote.manage"),
                    flex: 3,
                    href: "ChooseMeeting",
                    image: 'largeicon_3'
                },
                {
                    title: vm.$i18n.t("user.manage"),
                    flex: 3,
                    href: "User Profile",
                    image: 'largeicon_4'
                }
            ]
    }),
    computed:{
        ...mapState(["drawer"]),
        computeWidth(){
            if(this.drawer){
                return ''
            } else {
                return { "width": '85%', 'margin': 'auto' }
            }
        }
    },
    methods:{
        ...mapMutations(['setTitle', 'clearShoppingCart']),
        openPage(item){
            this.$router.push({
                name: item.href,
                params: {
                    item: item
                }
            });
        },
    },
    mounted(){
        this.setTitle(this.$i18n.t('title.Index'));
        if(this.$route.query.next=='order_summary') {
            this.clearShoppingCart()
            this.$router.push('/tables/order')
        } else if(this.$route.query.next=='order_failed') {
            this.$router.push({
                name: 'Order',
                params: {
                    failed: true
                }
            });
        }
    },
}
</script>

<style lang="sass" scoped>
.largeicon_1
    background: url(../../../assets/images/largeicon01.png) no-repeat center
    background-size: contain
.largeicon_2
    background: url(../../../assets/images/largeicon02.png) no-repeat center
    background-size: contain
.largeicon_3
    background: url(../../../assets/images/largeicon03.png) no-repeat center
    background-size: contain
.largeicon_4
    background: url(../../../assets/images/largeicon04.png) no-repeat center
    background-size: contain
#index-container
  height: 100%
.layout
  height: 95%
.v-card
    &:hover
        background: linear-gradient(#49ABEB, #0174C0)
        .v-card__text
            color: #ffffff
    .v-card__text
        color: #707070
</style>